import React from "react";
import * as F from "./functionsFlow.style";
import FeatureOne from "assets/images/functions/APP/app-feature-1.svg";
import FeatureTwo from "assets/images/functions/APP/app-feature-2.svg";
import FeatureThree from "assets/images/functions/APP/app-feature-3.svg";
import FeatureFour from "assets/images/functions/APP/app-feature-4.svg";

const FunctionsFlow = () => {
  return (
    <>
      <section id="section-43-18" class=" ct-section">
        <div class="">
          <div
            id="div_block-44-18"
            class="ct-div-block w-full grid items-stretch grid-cols-4 lg-grid-cols-2 gap-8 sm-grid-cols-1"
          >
            <div id="div_block-52-18" class="ct-div-block">
              <img
                id="image-53-18"
                alt=""
                src={
                  FeatureOne ||
                  "https://foodiary.app/data/Foodiary-App-Naehrwertanalyse-min.png"
                }
                class="ct-image shadow-md rounded-xl"
                srcset="https://foodiary.app/data/Foodiary-App-Naehrwertanalyse-min.png 1402w, https://foodiary.app/data/Foodiary-App-Naehrwertanalyse-min-300x197.png 300w, https://foodiary.app/data/Foodiary-App-Naehrwertanalyse-min-1024x672.png 1024w, https://foodiary.app/data/Foodiary-App-Naehrwertanalyse-min-768x504.png 768w"
                sizes="(max-width: 1402px) 100vw, 1402px"
              />
              <div
                id="text_block-54-18"
                class="ct-text-block color-dark text-xl font-medium mt-8 ml-2"
              >
                Nährwertanalyse
              </div>
              <div
                id="text_block-55-18"
                class="ct-text-block text-base font-normal color-paragraph mt-4 ml-2"
              >
                Bleib flexibel! Die Nährwertanalyse unterstützt dich beim
                Kalorien tracken und Erstellen eigener Rezepte.
              </div>
            </div>
            <div id="div_block-56-18" class="ct-div-block">
              <img
                id="image-57-18"
                alt="Foodiary-App-Wochenplanung-2-min"
                src={
                  FeatureTwo ||
                  "https://foodiary.app/data/Foodiary-App-Wochenplanung-2-min.png"
                }
                class="ct-image shadow-md rounded-xl"
                srcset="https://foodiary.app/data/Foodiary-App-Wochenplanung-2-min.png 1402w, https://foodiary.app/data/Foodiary-App-Wochenplanung-2-min-300x197.png 300w, https://foodiary.app/data/Foodiary-App-Wochenplanung-2-min-1024x672.png 1024w, https://foodiary.app/data/Foodiary-App-Wochenplanung-2-min-768x504.png 768w"
                sizes="(max-width: 1402px) 100vw, 1402px"
              />
              <div
                id="div_block-76-18"
                class="ct-div-block w-full relative mt-8 flex-row d-flex justify-content-between align-items-center"
              >
                <div
                  id="text_block-58-18"
                  class="ct-text-block text-xl font-medium ml-2 color-primary"
                >
                  Wochenplanung
                </div>
                <div id="code_block-77-18" class="ct-code-block ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4rem"
                    height="2.3rem"
                    viewBox="0 0 40 23"
                  >
                    <g
                      id="Gruppe_35391"
                      data-name="Gruppe 35391"
                      transform="translate(-186 -31)"
                    >
                      <rect
                        id="Rechteck_11407"
                        data-name="Rechteck 11407"
                        width="40"
                        height="23"
                        rx="5"
                        transform="translate(186 31)"
                        fill="#a5b5af"
                      ></rect>
                      <text
                        id="PRO"
                        transform="translate(206 47)"
                        fill="#fff"
                        font-size="14"
                        font-family="Rubik-Medium, Rubik"
                        font-weight="500"
                      >
                        <tspan x="-14.189" y="0">
                          PRO
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
              </div>
              <div
                id="text_block-59-18"
                class="ct-text-block text-base font-normal color-paragraph mt-4 ml-2"
              >
                Kein Alltagsstress. Deine Woche planst du ganz einfach mit Hilfe
                der Wochenplanung vor.
              </div>
            </div>
            <div id="div_block-60-18" class="ct-div-block ">
              <img
                id="image-61-18"
                alt="Foodiary-Einkaufsliste"
                src={
                  FeatureThree ||
                  "https://foodiary.app/data/Foodiary-Einkaufsliste.jpg"
                }
                class="ct-image shadow-md rounded-xl"
                srcset="https://foodiary.app/data/Foodiary-Einkaufsliste.jpg 1402w, https://foodiary.app/data/Foodiary-Einkaufsliste-300x197.jpg 300w, https://foodiary.app/data/Foodiary-Einkaufsliste-1024x672.jpg 1024w, https://foodiary.app/data/Foodiary-Einkaufsliste-768x504.jpg 768w"
                sizes="(max-width: 1402px) 100vw, 1402px"
              />
              <div
                id="text_block-62-18"
                class="ct-text-block color-dark text-xl font-medium mt-8 ml-2"
              >
                Einkaufsliste
              </div>
              <div
                id="text_block-63-18"
                class="ct-text-block text-base font-normal color-paragraph mt-4 ml-2"
              >
                Die Einkaufsliste gibt dir detaillierte Auskunft, welche
                Lebensmittel in welcher Menge für deine Woche benötigt werden.
              </div>
            </div>
            <div id="div_block-64-18" class="ct-div-block ">
              <img
                id="image-65-18"
                alt="Foodiary-App-Wissenswertes-min"
                src={
                  FeatureFour ||
                  "https://foodiary.app/data/Foodiary-App-Wissenswertes-min.png"
                }
                class="ct-image shadow-md rounded-xl"
                srcset="https://foodiary.app/data/Foodiary-App-Wissenswertes-min.png 1402w, https://foodiary.app/data/Foodiary-App-Wissenswertes-min-300x197.png 300w, https://foodiary.app/data/Foodiary-App-Wissenswertes-min-1024x672.png 1024w, https://foodiary.app/data/Foodiary-App-Wissenswertes-min-768x504.png 768w"
                sizes="(max-width: 1402px) 100vw, 1402px"
              />
              <div
                id="div_block-81-18"
                class="ct-div-block w-full relative mt-8 flex-row d-flex justify-content-between align-items-center"
              >
                <div
                  id="text_block-82-18"
                  class="ct-text-block text-xl font-medium ml-2 color-primary "
                >
                  Academy
                </div>
                <div id="code_block-83-18" class="ct-code-block ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4rem"
                    height="2.3rem"
                    viewBox="0 0 40 23"
                  >
                    <g
                      id="Gruppe_35391"
                      data-name="Gruppe 35391"
                      transform="translate(-186 -31)"
                    >
                      <rect
                        id="Rechteck_11407"
                        data-name="Rechteck 11407"
                        width="40"
                        height="23"
                        rx="5"
                        transform="translate(186 31)"
                        fill="#a5b5af"
                      ></rect>
                      <text
                        id="PRO"
                        transform="translate(206 47)"
                        fill="#fff"
                        font-size="14"
                        font-family="Rubik-Medium, Rubik"
                        font-weight="500"
                      >
                        <tspan x="-14.189" y="0">
                          PRO
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
              </div>
              <div
                id="text_block-67-18"
                class="ct-text-block text-base font-normal color-paragraph mt-4 ml-2"
              >
                In der Academy lernst du, alles rund um das Thema gesunde
                Ernährung, Vitamine & Mineralstoffe.
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FunctionsFlow;

import React from "react"
import { useSelector } from "react-redux"
import { getS3ImageUrl } from "configs/utils"
import * as F from "./coachingWithMe.style"

// Images
import Coach from "assets/images/placeholder.jpg"

const CoachingWithMe = () => {
  const coach = useSelector((state) => state.coach.coach)

  const imgUrl = coach?.cw_lp_picture_1
    ? getS3ImageUrl(coach?.cw_lp_picture_1)
    : Coach

  return (
    <F.CoachingWithMeContainer>
      <F.ContentSection>
        <F.Title className="ct-text-block font-bold color-dark text-left text-5xl">
          Foodiary Coaching <br /> mit <span>{coach?.prename}</span>
        </F.Title>
        <F.SubTitle className="ct-text-block color-paragraph text-base text-left">
          Als Ernährungsberater und Coach bei Foodiary unterstütze ich dich bei
          der Planung und Umsetzung deiner Ernährungsumstellung.
        </F.SubTitle>
        <F.Description className="ct-text-block color-paragraph text-base text-left">{coach?.cw_lp_textfield_1}</F.Description>
      </F.ContentSection>
      <F.TitleMobile className="ct-text-block font-bold color-dark text-left text-5xl">
        Foodiary Coaching <br /> mit <span>{coach?.prename}</span>
      </F.TitleMobile>
      <F.VisualContent>
        <img src={imgUrl} alt="coach" />
      </F.VisualContent>
    </F.CoachingWithMeContainer>
  )
}

export default CoachingWithMe
